import { createSelectors } from '@paladise/utils/zustand-selector'
import { subscribeWithSelector } from 'zustand/middleware'
import { createWithEqualityFn as create } from 'zustand/traditional'

/**
 * 記錄 impression 是否已發送
 * key 使用 react 的 useId 產生，避免使用 content_id 因為 content_id 可能會重複
 */
export type BoostTrackerState = {
  [key: string]: {
    isImpressionSent: boolean
  }
}

export type BoostTrakcerAction = {
  debug: () => BoostTrackerState
}

export type BoostTrackerStore = {
  state: BoostTrackerState
  actions: BoostTrakcerAction
}

export const useBoostTrackerStoreBase = create<BoostTrackerStore>()(
  subscribeWithSelector((set, get) => ({
    state: {
      defaultKey: {
        isImpressionSent: false,
      },
    },
    actions: {
      debug: () => get().state,
    },
  })),
)

export const useBoostTrackerStore = createSelectors(useBoostTrackerStoreBase)
