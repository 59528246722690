import { createSelectors } from '@paladise/utils/zustand-selector'
import { IMetadataAvatar } from 'features/discover/type'
import { createWithEqualityFn as create } from 'zustand/traditional'
import { subscribeWithSelector } from 'zustand/middleware'

export type SuggestAvatarsState = {
  avatars: IMetadataAvatar[]
  loading: boolean
}

export type SuggestAvatarsStore = SuggestAvatarsState

export const useSuggestAvatarsStoreBase = create<SuggestAvatarsStore>()(
  subscribeWithSelector((set, get) => ({
    avatars: [],
    loading: true,
  })),
)

export const useSuggestAvatarsStore = createSelectors(
  useSuggestAvatarsStoreBase,
)
