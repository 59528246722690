'use client'
import ScrollLoader from 'components/ScrollLoader'
import { IMetadataAvatar, MarketplacePlacement } from 'features/discover/type'
import { Post, PostSkeleton } from './Post'

type Props = {
  posts: IMetadataAvatar[]
  loading: boolean
  isEnd: boolean
  handleOnLoad: () => void
  placement?: keyof typeof MarketplacePlacement
  isAvatarProfilePage?: boolean
}

const MultipleSkeleton = (
  <div className="flex flex-1 flex-col justify-start gap-y-1">
    {Array(6)
      .fill(null)
      .map((_, index) => (
        <PostSkeleton key={index} />
      ))}
  </div>
)

export const PostList = ({
  posts,
  isEnd,
  loading,
  handleOnLoad,
  placement,
  isAvatarProfilePage = false,
}: Props) => {
  if (posts.length === 0 && !loading && !isEnd) return <>{MultipleSkeleton}</>
  return (
    <ScrollLoader
      loadingClassName="text-label-l1"
      callback={handleOnLoad}
      fetchEnd={isEnd}
      fetching={loading}
      loadingElement={<PostSkeleton />}
      twClass="min768:pb-0 pb-[108px]"
    >
      {posts
        ?.filter(post => post.post !== null && post.post !== undefined)
        .map((post, index) => (
          <Post
            key={`${post.id}_${index}`}
            data={post}
            placement={placement}
            isAvatarProfilePage={isAvatarProfilePage}
          />
        ))}
    </ScrollLoader>
  )
}
