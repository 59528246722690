'use client'

import { Button } from '@paladise/ui/components/ui/button'
import { clickDownloadLink } from 'components/DownloadLink'
import { ROUTE_PATH } from 'config/routerPath'
import { format } from 'date-fns/format'
import { useRouter } from 'next/navigation'
import { useRef } from 'react'
import { useFeed } from '../context/feed'
import { useCheckTextIsTruncate } from '../hooks/useCheckTextIsTruncate'
import PostTag from './PostTag'

const PostActionItem = [
  {
    icon: 'i-ps-export_s',
    key: 'share',
  },
  {
    icon: 'i-ps-more_horizontal',
    key: 'more',
  },
]

export const PostHeader = ({
  isAvatarProfilePage,
}: {
  isAvatarProfilePage: boolean
}) => {
  const router = useRouter()
  const textRef = useRef<HTMLDivElement>(null)
  const {
    profession_type,
    // subscription_plan = null,
    name,
    boost_info,
    id,
    post = null,
  } = useFeed()

  const { isTextTruncated } = useCheckTextIsTruncate({
    elementRef: textRef,
  })

  // TODO: V2.2 再透過綁定帳號判斷是否顯示 subscription logo
  const subscription_plan = false

  const isShowProfessionType =
    !isAvatarProfilePage && !!profession_type && !isTextTruncated

  const isShowDot = subscription_plan === null || isShowProfessionType

  return (
    <header className="mb-1 flex h-[18px] w-full">
      <div className="flex min-w-0">
        <p
          tabIndex={-1}
          ref={textRef}
          className="text-callout text-label-l1 min-w-0 max-w-fit shrink truncate"
          onClick={e => {
            e.stopPropagation()
            if (isAvatarProfilePage) return
            router.push(`${ROUTE_PATH.SIDEBAR.FEED}/${id}`)
          }}
        >
          {name}
        </p>
        {subscription_plan && (
          <img
            src="/images/plan_badge_icon.png"
            alt="plan badge"
            className="size-[20px] shrink-0"
          />
        )}
        {isShowProfessionType && (
          <PostTag
            isShowDot={!subscription_plan}
            label={profession_type}
            wrapperClassName="shrink-[999] min-w-0"
            labelClassName="truncate min-w-0"
          />
        )}
      </div>
      <div className="flex min-w-0 shrink-0">
        {isAvatarProfilePage && post?.published_at && (
          <PostTag
            label={format(post.published_at, 'yyyy/M/d')}
            wrapperClassName="flex-shrink-0"
          />
        )}
        {!isAvatarProfilePage && boost_info && (
          <PostTag
            isShowDot={isShowDot}
            label="Ad"
            wrapperClassName="flex-shrink-0"
          />
        )}
      </div>
      <div className="ml-auto flex gap-1" onClick={e => e.stopPropagation()}>
        {PostActionItem.map(actionItem => (
          <Button
            key={actionItem.key}
            variant="label-l2"
            size="icon"
            className="size-[18px]"
            onClick={clickDownloadLink}
          >
            <span className={`text-label-l2 size-[18px] ${actionItem.icon}`} />
          </Button>
        ))}
      </div>
    </header>
  )
}
