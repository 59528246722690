'use client'

import { useBoostTracker } from '@paladise/tracker/hooks/useBoostTracker'
import { useSendTracker } from '@paladise/tracker/hooks/useSendTracker'
import { MarketplacePlacementType } from '@paladise/tracker/types'
import { ROUTE_PATH } from 'config/routerPath'
import { IMetadataAvatar, MarketplacePlacement } from 'features/discover/type'
import { useRouter } from 'next/navigation'
import { useId, useRef } from 'react'
import { useBoostTrackerStore } from 'store/boost-tracker-store'
import { FeedProvider } from '../context/feed'
import { AvatarHeadshot, AvatarHeadshotSkeleton } from './AvatarHeadshot'
import { PostHeader } from './PostHeader'
import { RecommendSkeleton } from './RecommendCard'
import RecommendCardList from './RecommendCardList'

const articleStyle = 'flex flex-col w-full py-4 pl-3 pr-4 overflow-clip'

const Post = ({
  data,
  placement,
  isAvatarProfilePage = false,
}: {
  data: IMetadataAvatar
  placement?: keyof typeof MarketplacePlacement
  isAvatarProfilePage?: boolean
}) => {
  const router = useRouter()
  const { handleBoostTracker } = useSendTracker()
  const postRef = useRef<HTMLDivElement>(null)
  const uuid = useId()

  const getImpressionState = useBoostTrackerStore(
    store => (uuid: string) => store.state[uuid],
  )
  const setImpressionIsSent = useBoostTrackerStore(
    store => (uuid: string, isImpressionSent: boolean) => {
      store.state[uuid] = { isImpressionSent }
    },
  )

  const onBoostTrackerClick = () => {
    if (!placement || !data.boost_info) return

    handleBoostTracker({
      type: 'click',
      body: {
        content_id: data.id,
        event_token: data.boost_info.click_token[placement],
        placement,
        is_full_screen: false,
      },
    })
  }

  const handleClickPost = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    const target = event.target as HTMLElement
    const dialogElement = target.closest('[role="dialog"]')

    // check if click on dialog element
    if (dialogElement) return

    onBoostTrackerClick()

    if (isAvatarProfilePage) return
    router.push(
      `${ROUTE_PATH.SIDEBAR.MESSAGE}?id=${data.id}&postId=${data.post?.id || ''}`,
    )
  }

  useBoostTracker({
    elementRef: postRef,
    trackerParams: {
      content_id: data.id,
      ...(data.boost_info && placement
        ? {
            event_token: data.boost_info?.impression_token?.[placement],
          }
        : {}),
      placement: placement as MarketplacePlacementType,
      is_boost: !!data.boost_info,
      is_full_screen: false,
    },
    getImpressionState,
    setImpressionIsSent,
    uuid,
  })

  return (
    <FeedProvider value={{ ...data, placement, onBoostTrackerClick }}>
      <article
        ref={postRef}
        className={`${articleStyle} ${
          isAvatarProfilePage ? '' : 'cursor-pointer'
        }`}
        onClick={event => handleClickPost(event)}
        data-id={data.id}
      >
        <div className="flex w-full gap-x-2">
          <AvatarHeadshot
            head_shot_url={data.head_shot_url}
            name={data.name}
            id={data.id}
            isAvatarProfilePage={isAvatarProfilePage}
          />
          <div className="flex w-[calc(100%_-60px)] flex-1 flex-col">
            <PostHeader isAvatarProfilePage={isAvatarProfilePage} />
            {/* NOTE: 
                add tabIndex={-1} to disable chrome mobile's tap-to-search on a web app
                https://stackoverflow.com/questions/61640608/disable-chrome-mobiles-tap-to-search-on-a-web-app 
            */}
            <p className="text-body text-label-l1 mb-2" tabIndex={-1}>
              {data.post?.content}
            </p>
          </div>
        </div>
        <RecommendCardList card={data.post?.metadata} />
      </article>
    </FeedProvider>
  )
}

const PostContentSkeleton = ({ width }: { width: string }) => {
  return (
    <div
      style={{ width }}
      className={`bg-glass-separators h-[18px] w-full animate-pulse rounded-lg`}
    />
  )
}

const PostSkeleton = () => {
  return (
    <article className={articleStyle}>
      <div className="mb-2 flex w-full gap-x-2">
        <AvatarHeadshotSkeleton />
        <div className="flex w-full flex-1 flex-col gap-y-1">
          <div className="flex flex-col gap-y-1">
            <PostContentSkeleton width="100px" />
            <PostContentSkeleton width="275px" />
            <PostContentSkeleton width="160px" />
          </div>
        </div>
      </div>
      <div className="ml-[60px]">
        <RecommendSkeleton />
      </div>
    </article>
  )
}

export { Post, PostContentSkeleton, PostSkeleton }
