export const defaultDesktopPageSize = 24
export const defaultMobilePageSize = 12

export const defaultAvatarsSize = 12
export const defaultBrandAvatarsSize = 12
export const FILTER_OPTIONS = [
  { value: 'relevant', text: 'Most Relevant' },
  { value: 'hot', text: 'Hot' },
  { value: 'newest', text: 'Newest' },
] as const
