'use client'

import { RefObject, useEffect, useRef } from 'react'
import { BoostImpressionTrackerBody } from '../types'
import { useSendTracker } from './useSendTracker'

const MIN_VIEW_DURATION = 1000
const INTERSECTION_THRESHOLD = 0.25

const canSendBoostViewTracker = (duration: number) => {
  return duration >= MIN_VIEW_DURATION
}

type Props = {
  elementRef: RefObject<Element>
  trackerParams: BoostImpressionTrackerBody
  threshold?: number
  uuid: string
  getImpressionState: (uuid: string) => { isImpressionSent: boolean }
  setImpressionIsSent: (uuid: string, isImpressionSent: boolean) => void
}

export const useBoostTracker = ({
  elementRef,
  trackerParams,
  threshold = INTERSECTION_THRESHOLD,
  uuid,
  getImpressionState,
  setImpressionIsSent,
}: Props) => {
  const { handleBoostTracker } = useSendTracker()
  const viewStartTimeRef = useRef<number | null>(null)
  const observerRef = useRef<IntersectionObserver | null>(null)
  const isImpressionSent = getImpressionState(uuid)?.isImpressionSent ?? false

  useEffect(() => {
    if (!elementRef.current || observerRef.current) return

    const handleIntersection = (entry: IntersectionObserverEntry) => {
      if (entry.isIntersecting) {
        // 開始計時
        if (!viewStartTimeRef.current) {
          viewStartTimeRef.current = Date.now()
        }

        /**
         * 發送 impression
         * 一筆資料只發送一次但如果 load more時， api 回傳相同資料(contend_id)，則也會發送
         * 如果 placement 為 undefined，則不發送 impression(eg. avatarProfile Page 的 avatar 不會有 placement)
         *  */
        if (!isImpressionSent && trackerParams.placement) {
          handleBoostTracker({
            type: 'impression',
            body: trackerParams,
          })
          setImpressionIsSent(uuid, true)
        }
      } else {
        // 離開視窗，檢查並發送 view 事件
        const startTime = viewStartTimeRef.current
        if (startTime) {
          const duration = Date.now() - startTime
          if (canSendBoostViewTracker(duration)) {
            const { event_token, ...params } = trackerParams
            handleBoostTracker({
              type: 'view',
              body: {
                ...params,
                view_duration: duration,
              },
            })
          }
          viewStartTimeRef.current = null
        }
      }
    }

    observerRef.current = new IntersectionObserver(
      entries => {
        entries.forEach(entry => handleIntersection(entry))
      },
      { threshold },
    )

    observerRef.current.observe(elementRef.current)

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect()
        observerRef.current = null
      }
    }
  }, [
    elementRef,
    trackerParams,
    threshold,
    handleBoostTracker,
    isImpressionSent,
    setImpressionIsSent,
    uuid,
  ])
}
