'use client'

import { cn } from '@paladise/ui/lib/utils'
import React, { useEffect, useRef } from 'react'
import { twMerge } from 'tailwind-merge'

interface Prop {
  children: React.ReactNode
  fetchEnd: boolean
  option?: IntersectionObserver
  loadingClassName?: string
  fetching: boolean
  twClass?: string
  loadingElement?: JSX.Element
  callback: () => void
}

const ScrollLoader = ({
  children,
  callback,
  fetchEnd,
  option,
  loadingElement,
  loadingClassName,
  twClass,
  fetching,
}: Prop) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (fetchEnd) return
    let config = {}
    if (option) {
      config = { ...option }
    }

    const intersectionObserver = new IntersectionObserver(function (entries) {
      if (entries[0].intersectionRatio <= 0) return
      if (fetching) return
      callback()
    }, config)

    // start observing
    ref?.current && intersectionObserver.observe(ref.current)

    return () => intersectionObserver.disconnect()
  }, [fetchEnd, callback, option, fetching])

  return (
    <div className={twClass}>
      {children}

      {!fetchEnd && (
        <div className="flex w-full items-center justify-center" ref={ref}>
          {loadingElement ? (
            loadingElement
          ) : (
            <span
              className={cn(
                'i-ps-cell_loading ml-1 animate-spin text-[#FAFDFF]',
                loadingClassName,
              )}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default ScrollLoader
