import { useEffect, useState } from 'react'

export const useCheckTextIsTruncate = ({
  elementRef,
}: {
  elementRef: React.RefObject<HTMLElement>
}) => {
  const [isTextTruncated, setIsTextTruncated] = useState(false)

  useEffect(() => {
    if (!elementRef.current) return

    const checkTruncation = () => {
      const element = elementRef.current
      if (element) {
        setIsTextTruncated(element.scrollWidth > element.clientWidth)
      }
    }

    checkTruncation()

    window.addEventListener('resize', checkTruncation)

    return () => window.removeEventListener('resize', checkTruncation)
  }, [elementRef])

  return {
    isTextTruncated,
  }
}
