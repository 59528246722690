'use client'

import { NO_PHOTO_PATH } from '@paladise/config/constants'
import {
  AvatarFallback,
  AvatarImage,
  Avatar as AvatarUI,
} from '@paladise/ui/components/ui/avatar'
import { IMetadataAvatar } from 'features/discover/type'
import Link from 'next/link'
import { tv } from 'tailwind-variants'

const style = tv({
  slots: {
    link: 'flex items-start',
    avatarRoot: 'relative flex size-[52px] items-center justify-center',
    avatarImage: 'size-[36px] rounded-full',
    avatarWaveImage: 'absolute left-0 top-0 size-full',
    avatarImageFallback: 'bg-palup-white',
  },
})

type Props = Pick<IMetadataAvatar, 'head_shot_url' | 'name' | 'id'> & {
  isAvatarProfilePage?: boolean
}

const Avatar = ({
  head_shot_url,
  name,
}: {
  head_shot_url: IMetadataAvatar['head_shot_url']
  name: IMetadataAvatar['name']
}) => {
  const { avatarRoot, avatarImage, avatarImageFallback, avatarWaveImage } =
    style()

  return (
    <AvatarUI className={avatarRoot()}>
      <AvatarImage src={head_shot_url} alt={name} className={avatarImage()} />
      <AvatarImage
        src="/images/voice_wave_thinking_black.gif"
        alt="arrow-right"
        className={avatarWaveImage()}
      />
      <AvatarFallback className={avatarImageFallback()}>
        <img
          src={NO_PHOTO_PATH}
          alt="headshot fallback"
          className={avatarImage()}
        />
      </AvatarFallback>
    </AvatarUI>
  )
}

const AvatarHeadshot = ({
  head_shot_url,
  name,
  id,
  isAvatarProfilePage,
}: Props) => {
  const { link } = style()

  return isAvatarProfilePage ? (
    <div className={link()}>
      <Avatar head_shot_url={head_shot_url} name={name} />
    </div>
  ) : (
    <Link
      key={id}
      href={`/feed/${id}`}
      className={link()}
      onClick={e => {
        e.stopPropagation()
      }}
    >
      <Avatar head_shot_url={head_shot_url} name={name} />
    </Link>
  )
}

const AvatarHeadshotSkeleton = () => {
  const { avatarRoot, avatarImageFallback, avatarImage, link } = style()
  return (
    <div className={link()}>
      <AvatarUI className={avatarRoot()}>
        <AvatarFallback className={avatarImageFallback()}>
          <img
            src={NO_PHOTO_PATH}
            alt="headshot fallback"
            className={avatarImage()}
          />
        </AvatarFallback>
      </AvatarUI>
    </div>
  )
}

export { AvatarHeadshot, AvatarHeadshotSkeleton }
